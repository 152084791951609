const errors = {
  NotAuthorizedException: 'Tili on jo vahvistettu',
  CodeMismatchException: 'Vahvistuskoodia ei tunnistettu.',
  InvalidUsernameOrPasswordException: 'Väärä tunnus tai salasana',
  UsernameExistsException: 'Käyttäjätunnus on jo olemassa',
  InvalidPasswordException:
    'Salasana ei ole tarpeeksi vahva. Salasanan pitää olla vähintään kahdeksan merkkiä pitkä.',
  ServerError:
    'Pyyntö epäonnistui. Kokeile uudelleen tai ole yhteydessä asiakaspalvelumme.',
  UnknownPaymentOption:
    'Maksuvaihtoehtoa ei tunnisteta. Ole yhteydessä asiakaspalvelumme.',
  BamboraError:
    'Virhe maksupalvelussa. Kokeile uudelleen tai ole yhteydessä asiakaspalvelumme.',
  SubscriptionNotExists: 'Tilausta ei löytynyt.',
  NotValidSuspendOption: 'Virheellinen lopetusvaihtoehto.',
  UserNotExists: 'Käyttäjää ei ole olemassa.',
  ERPCustomerNotPosted: 'Et ole asettanut asiakasnumeroa.',
  CustomerNotFound: 'Tietojasi ei löytynyt.',
  POSTERPCustomersCountExceeded:
    'Asiakasnumeron liittäminen ei onnistunut. Olethan yhteydessä asiakaspalveluumme.',
  LastNameGivenDoesNotMatch:
    'Asiakasnumeroon liitetty sukunimi ei täsmää. Tarkasta sukunimi.',
  LastNameOrCountryGivenDoesNotMatch:
    'Asiakasnumeroon liitetty sukunimi tai maa ei täsmää. Tarkista tieto.',
  PostalCodeGivenDoesNotMatch:
    'Asiakasnumeroon liitetty postinumero ei täsmää. Tarkasta postinumero.',
  LastNameNotGiven:
    'Asiakasnumerolla ulkomaalainen osoite. Tarkista valintasi tai ole yhteydessä asiakaspalveluun.',
  PostalCodeNotGiven:
    'Asiakasnumerolla kotimainen osoite. Tarkista valintasi tai ole yhteydessä asiakaspalveluun.',
  EmailAddressSSOAlreadyExists: 'Sähköposti on jo liitetty toiseen tiliin.',
  PhoneNumberSSOAlreadyExists: 'Puhelinnumero on jo liitetty toiseen tiliin.',
  InvalidStartDate: 'Virheellinen aloituspäivämäärä.',
  InvalidEndDate: 'Virheellinen loppumispäivämäärä.',
  UserAlreadyRegistered: 'Käyttäjä on jo rekisteröitynyt.',
  SSOUserNotFound: 'A-tunnusta ei löytynyt.',
  PermissionNotFound: 'Ei käyttöoikeutta.',
  CannotDeletePermission: 'Käyttöoikeutta ei voida poistaa.',
  CustomerAlreadyRegistered:
    'Asiakasnumero on jo liitetty toiseen tunnukseen. Otathan yhteyttä asiakaspalveluumme.',
  FirstNameNotGiven: 'Etunimi on syötettävä',
  NameGivenDoesNotMatch: 'Nimi ei täsmää. Tarkista tieto.',
  ReadOnlyMode: 'Palvelussa on huoltokatko. Yritä myöhemmin uudestaan.'
}

export default errors
